<template>
  <div class="col-auto" >
    <Button color="green_light" width="100" btnText="Back" icon2="arrow" @buttonClicked="goBack">
      <template v-slot:iconStart>
        <IconArrowBack color="white" />
      </template>
    </Button>
  </div>
  <div class="col-auto">
    <Button color="green_light" width="100" btnText="Setup Recurring Payment" icon="arrow" @buttonClicked="goNext" :disabled="nextDisabled">
      <IconArrowForward color="white" />
    </Button>
  </div>
</template>
<script>
import { defineAsyncComponent } from 'vue'

export default {
  components: {
    Button: defineAsyncComponent(() => import('@/components/Button.vue')),
    IconArrowBack: defineAsyncComponent(() => import('@/components/icons/IconArrowBack.vue')),
    IconArrowForward: defineAsyncComponent(() => import('@/components/icons/IconArrowForward.vue'))
  },
  name: 'SetupNewFooter',
  props: ['activeComponent', 'theSteps', 'donation', 'donationType', 'selectedProject'],
  emits: ['addDonationToCart', 'update:activeComponent', 'close'],
  computed: {
    activeComponentValue: {
      get () {
        return this.activeComponent
      },
      set (val) {
        this.$emit('update:activeComponent', val)
      }
    },
    nextDisabled () {
      if (this.donation.amount < 0.01 || Object.keys(this.selectedProject).length === 0 || !this.donationType) {
        return true
      } else {
        return false
      }
    }
  },
  methods: {
    goBack () {
      const index = this.theSteps.findIndex(x => x === this.activeComponentValue)
      if (index === 0) {
        this.$emit('close')
      } else {
        this.activeComponentValue = this.theSteps[index - 1]
      }
    },
    goNext () {
      this.$emit('addDonationToCart')
      const index = this.theSteps.findIndex(x => x === this.activeComponentValue)
      this.activeComponentValue = this.theSteps[index + 1]
    }
  }
}

</script>
